import React from 'react';
import styled from 'styled-components';
import {
  typography,
  TypographyProps,
  margin,
  MarginProps,
  lineHeight,
  LineHeightProps
} from 'styled-system';
import { Box } from './Box';

interface HeadingProps extends TypographyProps, MarginProps, LineHeightProps {
  children: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
}

const headings: Array<keyof JSX.IntrinsicElements> = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6'
];

export function Heading({ children, level, ...rest }: HeadingProps) {
  return (
    <StyledHeading as={headings[level - 1]} {...rest}>
      {children}
    </StyledHeading>
  );
}

const StyledHeading = styled(Box)<Pick<HeadingProps, 'color'>>`
  margin: 0;
  color: ${({ color = 'var(--black)' }) => color};
  ${typography}
  ${margin}
  ${lineHeight}
`;
